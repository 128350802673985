const ChatBoxFooter = () => (
  <div
    style={{
      textAlign: "center",
      padding: "10px",
      fontSize: "0.85rem",
      textShadow: "1px 1px 0px white",
    }}
  >
    Powered by{" "}
    <a
      href="https://ncchatbot.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      NCC chatbot
    </a>
  </div>
);

export default ChatBoxFooter;
